import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled, { css } from "styled-components"
import { media } from '../styles/media'
import TransitionLink from "gatsby-plugin-transition-link"

//icon
import { RiCopyrightLine } from "react-icons/ri"
import { IconContext } from "react-icons"

const Copyr = () => (
  <IconContext.Provider value={{}}>
    <RiCopyrightLine />
  </IconContext.Provider>
)

const StyledFooterWrapper = styled.div`
  margin-top: 64px;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.primary.brown};
  padding: 48px 0 34px;
  ${() => media.tablet(css`
    padding:48px 5%;
    justify-content:space-between;
  `)}
`

const StyledList = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
  a {
    margin: 8px 0;
  }
  ${() => media.tablet(css`
    width:50%;
    flex-flow:row nowrap;
    a{
      margin:0 16px;
    }
  `)}
`

const StyledFoots = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  p {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    span {
      margin: 0 8px;
    }
  }
  .francois{
    display:none;
  }
  ${() => media.tablet(css`
    width:25%;
    flex-flow:column nowrap;
    align-items:flex-end;
    .francois{
      display:block;
      width:40px;
      margin-bottom:16px;
    }
  `)}
`

const StyledImg = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  a:first-child {
    width: 160px;
    margin: 0 auto;
  }
  a:nth-child(2) {
    width: 64px;
    margin: 16px 0;
  }
  ${() => media.tablet(css`
    width:25%;
    a:nth-child(2){
      display:none!important;
    }
  `)}
`

const Footer = () => {
  return (
    <StyledFooterWrapper>
      <StyledImg>
        <TransitionLink to="/" exit={{ length: 0.3 }} entry={{ delay: 0.3 }}>
          <StaticImage src="../images/logoMain.svg" alt="暮らしと食物繊維" />
        </TransitionLink>
        <a
          href="https://www.francois.co.jp/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <StaticImage
            src="../images/logo-francois.svg"
            alt="株式会社フランソア"
          />
        </a>
      </StyledImg>
      <StyledList>
        <a
          href="https://www.francois.co.jp/"
          target="_blank"
          rel="noopener noreferrer"
        >
          フランソア・ホームページ
        </a>
        <a
          href="https://corporate.francois.co.jp/"
          target="_blank"
          rel="noopener noreferrer"
        >
          企業情報
        </a>
      </StyledList>
      <StyledFoots>
        <StaticImage
          src="../images/logo-francois.svg"
          alt="株式会社フランソア"
          className='francois'
        />
        <p>
          <span>Copyright</span>
          <Copyr />
          <span>Francois Co., Ltd.</span>
        </p>
      </StyledFoots>
    </StyledFooterWrapper>
  )
}

export default Footer
