import React from "react"
import styled, { css } from "styled-components"
import { media } from '../../styles/media'

const StyledDiv = styled.div`
  width: ${({ width }) => width || "100px"};
  height: ${({ height }) => height || "100px"};
  background-color: ${({ bgColor }) => bgColor || "#eee"};
  border: ${({ bgColor }) => `1px solid ${bgColor}`};
  border-radius: 16px;
  display:flex;
  flex-flow:row nowrap;
  align-items:center;
  justify-content:center;
  margin-bottom:32px;
  ${() => media.tablet(css`
    width:300px;
    height:300px;
  `)}
  ${() => media.pc(css`
    width:375px;
  `)}

`

const CardWrapper = props => {
  const { width, height, bgColor, children } = props

  return (
    <StyledDiv width={width} height={height} bgColor={bgColor}>
      {children}
    </StyledDiv>
  )
}

export default CardWrapper
