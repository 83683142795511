import styled, { css } from "styled-components"
import { media } from '../../styles/media'

export const StyledP = styled.p`
  white-space: pre-line;
  word-wrap: break-word;
  text-align:center;
  font-weight:700;
  font-size:12px;
  ${() => media.tablet(css`
  height:50px;
  `)}

`
export const StyledLeft = styled.div`
  display:flex;
  flex-flow:column nowrap;
  align-items:center;
  justify-content:center;
  width:60%;
  margin-left:5%;
  .gatsby-image-wrapper{
    width:80%;
    height:auto;
    margin:16px 0 32px;
  }
`

export const StyledRight = styled.div`
  width:35%;
  height:100%;
  display:flex;
  flex-flow:column nowrap;
  align-items:flex-start;
  justify-content:center;
  .gatsby-image-wrapper{
    width:60%;
    margin-left:5%;
    margin-top:16px;
    margin-bottom:16px;
    height:auto;
  }
`
