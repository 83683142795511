/**
 * Third-party libraries
 */
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled, { css } from "styled-components"
import { motion } from "framer-motion"
/**
 * First-party files
 */
import { media } from "styles/media"
import MainLogo from "images/assets/mainLogo.svg"
import BitsOfKnowledgeCard from "components/Card/BitsOfKnowledgeCard"
import Footer from "components/Footer"
import LaboCard from "components/Card/LaboCard"
import Layout from "components/Layout"
import ThirtyYearsCard from "components/Card/ThirtyYearsCard"
import FadeEffect from "components/ui/FadeEffect"
// import Card from "components/Cards/Card"
// import LaboCardContent from "components/Cards/LaboCardContent"
// import { Element } from "react-scroll"
// components
// import SEO from "../components/SEO"
// import MainVisual from "components/MainVisual"
// import Teaser from "components/Teaser/Teaser"
import SEO from "../components/SEO"

const StyledMain = styled.div`
  ${() =>
    media.tablet(css`
      height: 60vh;
      position: relative;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 48px;
      .background {
        position: absolute;
        height: 100% !important;
        .gatsby-image-wrapper {
          height: 100%;
          width: auto;
        }
      }
      .contents {
        width: 50%;
        position: absolute;
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: center;
        margin: 0 8% !important;
        .lead {
          position: relative;
          width: 100%;
          margin: 0 auto;
          font-size: 1.12em;
          font-weight: 900;
        }
      }
    `)}
  width: 100%;
  position: relative;
  ${() =>
    media.pc(css`
      height: 100vh;
    `)}

  .background {
    width: 100%;
    height: 40vh;
    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .contents {
    margin: 0 16px;
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
    justify-content: center;
    .gatsby-image-wrapper {
      width: 72%;
      margin: 16px auto 0;
      ${() =>
        media.tablet(css`
          margin: 24px 0 0;
        `)}
    }
  }
  .lead {
    margin: 16px 0 32px;
    p {
      padding: 8px 0;
    }
  }
`

const StyledCards = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  ${() =>
    media.tablet(css`
      width: 100%;
      flex-flow: row wrap;
      justify-content: space-between;
      padding: 0 5%;
    `)}
`

const setInitial = entry => {
  // if (entry.state.prevPage) {
  //   return { x: entry.state.x }
  // }

  return { x: 0, opacity: 0 }
}

const setAnimation = (transitionStatus, exit) => {
  // if (transitionStatus === "exiting") {
  //   if (exit.state.nextPage === "labo") {
  //     return { x: -exit.state.x }
  //   }
  //   if (exit.state.nextPage === "bitsOfKnowledge") {
  //     return { x: -exit.state.x }
  //   }
  // }
  return { x: 0, opacity: 1 }
}

const setTransition = entry => {
  // if (Object.keys(entry.state).length > 0) {
  //   return { ease: "easeOut", duration: entry.length || 0.3 }
  // }

  // if (Object.keys(entry.state).length === 0) {
  //   return { ease: "easeOut", duration: 0.6 }
  // }
  return { ease: "easeOut", duration: 0.6 }
}

const IndexPage = props => {
  const { pageData, laboCardData: laboCard, mainLogo } = useStaticQuery(query)
  const { leadTexts } = pageData
  const { location, transitionStatus, entry, exit } = props

  return (
    <Layout>
      <SEO />
      {/* <FadeEffect> */}
      <motion.div
        initial={setInitial(entry)}
        animate={setAnimation(transitionStatus, exit)}
        transition={setTransition(entry)}
      >
        <StyledMain>
          <div className="background">
            <StaticImage
              src="../images/naturel-main.jpg"
              alt="くらしオンライン"
              placeholder="none"
            />
          </div>
          <div className="contents">
            <StaticImage
              src="../images/mainLogo.svg"
              alt="くらしと食物繊維"
              placeholder="none"
            />
            <div className="lead">
              <p>{leadTexts.paragraph1}</p>
              <p>{leadTexts.paragraph2}</p>
              <p>{leadTexts.paragraph3}</p>
            </div>
          </div>
        </StyledMain>
        {/* Cards */}
        <StyledCards>
          <LaboCard location={location} />
          <ThirtyYearsCard location={location} />
          <BitsOfKnowledgeCard location={location} />
        </StyledCards>
        <Footer />
      </motion.div>
      {/* </FadeEffect> */}
    </Layout>
  )
}
{
  /* <Teaser />
    <Layout>
      <MainVisual />

        <Element name="sample-scroll">
        <div style={{ height: "300px", backgroundColor: "#eee" }}>
          some component here!
        </div>
      </Element>
      <div style={{ height: "300px", backgroundColor: "#333", color: "#fff" }}>
        some component here!
      </div>
    </Layout>
   ) */
}

const query = graphql`
  {
    pageData: pagesYaml(pageName: { eq: "topPage" }) {
      leadTexts {
        paragraph1
        paragraph2
        paragraph3
      }
    }
  }
`

export default IndexPage
