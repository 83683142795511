import React from "react"
import { Link } from "gatsby"
import TransitionLink from "gatsby-plugin-transition-link"
import styled, { css } from "styled-components"

// import { theme } from "styles/theme"

//icon
import { FiChevronRight } from "react-icons/fi"
import { IconContext } from "react-icons"

import { setSwipeState } from "../../lib/setTransitionLinkState"

const Arrow = () => (
  <IconContext.Provider value={{ size: "20px" }}>
    <FiChevronRight />
  </IconContext.Provider>
)

const StyledLink = styled(TransitionLink)`
  width: 144px;
  height: 40px;
  border-radius: 46px;
  background-color: ${props => props.theme.colors.primary.white};
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 700;
  position: relative;
  box-shadow: ${({ sdcolor }) => `2px 2px 0 ${sdcolor}`};
  svg {
    position: absolute;
    right: 12px;
  }
  // overflow: hidden;
  transition: all 400ms;

  &:active {
    box-shadow: ${({ sdcolor }) => `0 0 0 ${sdcolor}`};
  }

  &:active:after {
    box-shadow: ${({ sdcolor }) => `0 0 0 ${sdcolor}`};
  }
`

const CardButton = props => {
  if (props.location?.pathname === "/") {
    return (
      <StyledLink
        to={props.link}
        sdcolor={props.sdColor}
        activeClassName="active"
        // exit={{ delay: 1, length: 0.1, state: { opacity: 0 } }}
        entry={{ delay: 0.2, length: 0.2, state: { opacity: 0 } }}
      >
        詳しく見る
        <Arrow />
      </StyledLink>
    )
  }
  return (
    <StyledLink
      to={props.link}
      sdcolor={props.sdColor}
      exit={setSwipeState(
        typeof window !== "undefined" && window.innerWidth,
        "EXIT",
        "OPEN"
      )}
      entry={setSwipeState(
        typeof window !== "undefined" && window.innerWidth,
        "ENTRY",
        "OPEN"
      )}
      activeClassName="active"
    >
      詳しく見る
      <Arrow />
    </StyledLink>
  )
}

export default CardButton
