import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import theme from "styles/theme"
import { StyledP, StyledLeft, StyledRight } from "./cards.style"
import CardWrapper from "./CardWrapper"
import CardButton from "./CardButton"

const BitsOfKnowledgeCard = props => {
  const { data } = useStaticQuery(query)
  const { bitsOfKnowledgeCard } = data
  const { location } = props
  return (
    <CardWrapper
      width="343px"
      height="300px"
      bgColor={theme.colors.primary.green}
    >
      <StyledLeft>
        <StyledP>{bitsOfKnowledgeCard.leadText}</StyledP>
        <StaticImage
          src="../../images/logoKnow.svg"
          alt="食物繊維の豆知識"
          placeholder="none"
        />
        <CardButton
          link="/bits-of-knowledge/"
          sdColor={theme.colors.shadow.green}
          location={location}
        />
      </StyledLeft>
      <StyledRight>
        <StaticImage
          src="../../images/blue.svg"
          alt="キャラクター"
          placeholder="none"
        />
        <StaticImage
          src="../../images/green.svg"
          alt="キャラクター"
          placeholder="none"
        />
      </StyledRight>
    </CardWrapper>
  )
}

const query = graphql`
  {
    data: componentsYaml {
      bitsOfKnowledgeCard {
        leadText
      }
    }
  }
`

export default BitsOfKnowledgeCard
