import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import theme from "styles/theme"
import { StyledP, StyledLeft, StyledRight } from "./cards.style"
import CardWrapper from "./CardWrapper"
import CardButton from "./CardButton"

const LaboCard = props => {
  const { data } = useStaticQuery(query)
  const { laboCard } = data
  const { location } = props
  return (
    <CardWrapper
      width="343px"
      height="270px"
      bgColor={theme.colors.primary.pink}
    >
      <StyledLeft>
        <StyledP>{laboCard.leadText}</StyledP>
        <StaticImage
          src="../../images/logoLabo.svg"
          alt="fiber laboratory"
          placeholder="none"
        />
        <CardButton
          link="/labo/"
          sdColor={theme.colors.shadow.pink}
          location={location}
        />
      </StyledLeft>
      <StyledRight>
        <StaticImage
          src="../../images/doll1.svg"
          alt="帝人とフランソア"
          placeholder="none"
        />
      </StyledRight>
    </CardWrapper>
  )
}

const query = graphql`
  {
    data: componentsYaml {
      laboCard {
        leadText
      }
    }
  }
`

export default LaboCard
